<div class="layout">
  <header class="header">
    <app-header></app-header>
  </header>
  <main class="body-start">
    <router-outlet></router-outlet>
  </main>
  <footer>
    <app-footer></app-footer>
  </footer>
</div>