<app-header></app-header>
<section class="login-section">
    <div class="container-fluid">
        <div class="row justify-content-center align-items-center">
            <div class="col-xl-4 col-sm-7 col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="text-center mb-3">
                            <img src="../../../assets/img/logo-black.svg" alt="123" class="img-fluid">
                        </div>
                        <h2 class="fw-bold mt-5">{{labelConstant.twoStepVerification}}</h2>
                        <div class="verification-content mb-3">
                            <span>{{labelConstant.sixxDigitOTP}}<label>
                                {{email}}</label></span>
                        </div>
                        <div class="verification-form">
                            <form class="login-from">
                                <div class="form-group mb-3">
                                    <label for="verify-label"
                                        class="form-label">{{labelConstant.enterCodeBelow}}</label>
                                    <div class="d-flex">
                                        <ng-otp-input (onInputChange)="onOtpChange($event)"
                                            [config]="{length:6 ,allowNumbersOnly:true}"></ng-otp-input>
                                    </div>
                                </div>
                                <a id="emailHelp" class="form-text"></a>
                                <button type="submit" class="login-btn mt-4 mb-4"
                                    (click)="verifyOtp()">{{labelConstant.submit}}</button>
                                <div class="form-check d-flex justify-content-center mb-3 ps-0">
                                    <label class="form-check-label " for="exampleCheck1">
                                        <a routerLink="/registration"
                                            style="color: #667085;">{{labelConstant.goToBack}}</a>
                                    </label><br>
                                </div>
                                <label >
                                    <b><i>{{ labelConstant.spamCheck }}</i></b>
                                </label>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-login-register-footer></app-login-register-footer>