import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ApiUrlHelper {

  public apiUrl = {
    tooltipMsg: 'assets/tooltips.JSON',

    Account: {
      CustomerLogin: 'customer/account/login',
      CustomerSignUp: 'customer/customer/save',
      CustomerLogOut: 'customer/account/logout',
      CustomerForgotPass: 'customer/account/forget-password',
      CustomerResetPass: 'customer/account/success-forget-password',
      CustomerOtpVerify: 'customer/customer/send-verification-code'
    },

    About: {
      AboutImageList: 'customer/setting/about-us/about-us-image-list',
      AboutContentList: 'customer/setting/about-us/list'
    },

    Testimonial: {
      GetTestimonialList: 'customer/setting/about-us/testimonial-review-list'
    },

    Event: {
      GetEventList: 'customer/event/list',
      GetEventDetails: 'customer/event/event-detail',
      GetQuestionOptions: 'customer/event/event-question-list',
    },

    ContactUs: {
      Contact: 'customer/contact-us/send'
    },

    country: {
      GetCountryList: 'admin/country/list'
    },

    State: {
      GetStateList: 'admin/state/list/{Id}'
    },

    city: {
      GetCityList: 'admin/city/list/{Id}'
    },

    MyAccount: {
      CustomerUpdate: 'customer/customer/update',
      GetCustomerById: 'customer/customer/get-customer-detail/{Id}',
      SaveCustomerAddress: 'customer/customer-address/save',
      GetCustomerAddressList: 'customer/customer-address/list',
      GetCustomerAddressById: 'customer/customer-address/get-customer-address/{Id}',
      DeleteCustomerAddress: 'customer/customer-address/delete/{Id}',
      ChangePassword: 'customer/account/change-password',
      GetCustomerDefaultAddressById: 'customer/customer-address/get-customer-default-address',
      GetPurchaseList: 'customer/customer/booked-event-list',
      GetPassesList: 'customer/customer/booked-session-list'
    },
    
    PrivacyPolicy: {
      GetPrivacyPolicy: 'customer/setting/privacy-policy/page'
    },

    TermsAndConditions: {
      GetTermsAndConditions: 'customer/setting/terms-condition/page'
    },

    Appoinment: {
      SaveAppoinment: 'customer/appointment/save',
      AppoinmentTypeDropdown: 'customer/appointment/appointment-type-drop-down',
      GetAppoinmentList: 'customer/appointment/appointment-list',
      GetAppoinmentById: 'customer/appointment/get-appointment-detail/{Id}',
      DeleteAppoinment: 'customer/appointment/delete/{Id}'
    },

    SocialMediaLinks: {
      GetSocialMediaLinks: 'customer/setting/social-media/list/{Id}'
    },

    Session: {
      GetUpcomingSessionList: 'customer/class/upcoming-list',
      GetBookedSessionList: 'customer/class/booked-list',
      GetUpcomingSessionForViewMore: 'customer/class/upcoming-list-by-day',
      GetPassList: 'customer/payment-pass/list/{Id}',
      GetSessionDataById: 'customer/class/session-details/{Id}',
      GetBookedSessionForViewMore: 'customer/class/booked-list-by-day',
      ReportPass: 'customer/report/register',
      GetReportPassDetail: 'customer/report/getdetail',
      EarlyCancel: 'customer/order/early-cancel-session'
    },

    Cart: {
      AddCart: 'customer/cart/save',
      UpdateCart: 'customer/cart/update',
      GetCartList: 'customer/cart/list',
      DeleteCartDetail: 'customer/cart/delete/{CartDetailId}',
      ApplyCouponCode: 'customer/cart/apply-coupon-code/{CouponCode}',
      CreateStipeTokenId: 'customer/stripe/payment-intent-method',
      GetCardType: 'customer/stripe/get-card-type',
      GetCartEventDetails: 'customer/cart/cart-question-details',
      CheckPassavailable: 'customer/cart/pass-available',
      UpdateDefaultAddress: 'customer/customer-address/update-default-address',
      UpdateCartQuestion: 'customer/cart/update-cart-question'
    },

    Order: {
      PlaceOrder: 'customer/order/place-order',
      BookSession: 'customer/order/book-session',
      RemoveOrder: 'customer/order/remove',
    },

    MyProfile: {
      UpdateCustomer: 'customer/customer/update',
      GetCustomerById: 'customer/customer/get-customer-detail/{Id}',
      AddEditAddress: 'customer/customer-address/save',
      GetAddressList: 'customer/customer-address/list',
      GetAddressById: 'customer/customer-address/get-customer-address/{Id}',
      DeleteAddress: 'customer/customer-address/delete/{Id}',
      ChangePassword: 'customer/account/change-password'
    },
  };
}