<app-header></app-header>
<section class="login-section">
  <div class="container-fluid">
    <div class="row justify-content-center align-items-center">
      <div class="col-xl-4 col-sm-7 col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center mb-3">
              <img src="../../../assets/img/logo-black.svg" alt="" class="img-fluid">
            </div>
            <h2 class="fw-bold pb-3">{{labelConstants.Signup}}</h2>
            <form class="login-from" [formGroup]="signUpForm" (ngSubmit)="signUpCustomer()">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group mb-3">
                    <label class="form-label">{{labelConstants.FirstName}}<span>*</span></label>
                    <input type="text" class="form-control" oninput="this.value = this.value.replace(/^\s+/g, '')"
                      placeholder="Enter First name" aria-label="First name" formControlName="FirstName"
                      maxlength="49" />
                    <span class="text-danger"
                      *ngIf="submitted && signUpFormControls['FirstName'].errors?.['required']">{{errorConstants.FirstNameRequired}}</span>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group mb-3">
                    <label class="form-label" for="cname">{{labelConstants.LastName}}<span>*</span></label>
                    <input type="text" class="form-control" oninput="this.value = this.value.replace(/^\s+/g, '')"
                      placeholder="Enter Last name" aria-label="Last name" formControlName="LastName" maxlength="49" />
                    <span class="text-danger"
                      *ngIf="submitted && signUpFormControls['LastName'].errors?.['required']">{{errorConstants.LastNameIsRequired}}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group mb-3">
                    <label for="exampleInputEmail1" class="form-label">{{labelConstants.Emailaddress}}<span
                        class="text-danger">*</span></label>
                    <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Enter Email"
                      aria-describedby="emailHelp" formControlName="Email"
                      oninput="this.value = this.value.replace(/^\s+/g, '')"
                      oninput="this.value = this.value.toLowerCase()" maxlength="100" />
                    <span class="text-danger"
                      *ngIf="submitted && signUpFormControls['Email'].errors?.['required']">{{errorConstants.EmailRequired}}</span>
                    <span class="text-danger"
                      *ngIf="submitted && signUpFormControls['Email'].errors?.['pattern']">{{errorConstants.EmailNotValid}}</span>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group mb-3">
                    <label for="exampleInputPassword1"
                      class="form-label">{{labelConstants.Password}}<span>*</span></label>
                    <div class="position-relative">
                      <input oninput="this.value = this.value.replace(/^\s+/g, '')" id="inputPassword"
                        [type]="showNewPassword ? 'text' : 'password'" class="form-control"
                        formControlName="NewPassword" placeholder="Enter New password" maxlength="99" />
                      <div class="icon-set" *ngIf="!showNewPassword"
                        (click)="togglePasswordVisibility('showNewPassword')">
                        <img src="../../../assets/img/eye_open.svg" alt="">
                      </div>
                      <div class="icon-set" *ngIf="showNewPassword"
                        (click)="togglePasswordVisibility('showNewPassword')">
                        <img src="../../../assets/img/eye_close.svg" alt="">
                      </div>
                    </div>
                    <span class="text-danger"
                      *ngIf="submitted && signUpFormControls['NewPassword'].errors?.['required']">{{errorConstants.NewPasswordRequired}}</span>
                    <span class="text-danger"
                      *ngIf="submitted && signUpFormControls['NewPassword'].errors?.['pattern']">{{errorConstants.PasswordValidations}}</span>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group mb-3">
                    <label for="exampleInputPassword1"
                      class="form-label">{{labelConstants.ConfirmPassword}}<span>*</span></label>
                    <div class="position-relative">
                      <input oninput="this.value = this.value.replace(/^\s+/g, '')"
                        [type]="showConfirmPassword ? 'text' : 'password'" class="form-control" id="inputPassword2"
                        formControlName="ConfirmNewPassword" placeholder="Enter Confirm password" maxlength="99" />
                      <div class="icon-set" *ngIf="!showConfirmPassword"
                        (click)="togglePasswordVisibility('showConfirmPassword')">
                        <img class="eye-open" src="../../../assets/img/eye_open.svg" alt="">
                      </div>
                      <div class="icon-set" *ngIf="showConfirmPassword"
                        (click)="togglePasswordVisibility('showConfirmPassword')">
                        <img class="eye-close" src="../../../assets/img/eye_close.svg" alt="">
                      </div>
                    </div>
                    <span class="text-danger"
                      *ngIf="submitted && signUpFormControls['ConfirmNewPassword'].errors?.['required']">{{errorConstants.ConfirmPasswordRequired}}</span>
                    <span class="text-danger" *ngIf="
                        submitted &&
                        signUpForm.hasError('mismatch') &&
                        signUpForm.get('ConfirmNewPassword').touched && !(signUpFormControls['ConfirmNewPassword'].errors?.['required'])
                      ">
                      {{errorConstants.PasswordsDoNoMatch}}
                    </span>
                  </div>
                </div>
              </div>
              
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group mb-3">
                    <label for="exampleInputEmail1" class="form-label">{{labelConstants.HealthConcern}}</label>
                        <textarea class="form-control" id="exampleInputEmail1" placeholder="Enter Health Concern" formControlName="HealthConcern" maxlength="250" oninput="this.value = this.value.replace(/^\s+/g, '')"></textarea>

                  </div>
                </div>
              </div>
              <button type="submit" class="login-btn mt-4 mb-4">
                {{labelConstants.Register}}
              </button>
              <div class="form-check d-flex justify-content-center mb-3">
                <label class="form-check-label" for="exampleCheck1">{{labelConstants.AlreadyHaveAccount}}
                  <a routerLink="/login" style="color: #667085;">{{labelConstants.Signin}}</a>
                </label>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-login-register-footer></app-login-register-footer>