export const environment = {
  envtype: 'production',
  ApiBaseUrl: 'https://superariapi.devservices.uk/api/',
  //ApiBaseUrl: 'http://59.144.96.161:2035/api/',
  StripeKey: 'pk_test_51OomjJCAkRMAgYyFYHobqaDMbGLjGKag8WxV35SXafcd8nuBcWS6jUqefTJFqUL7Qk8dkJ2ORCxpIrERTtYYBpLX00rGhyVJ6F',
  isUnderMaintance: false,
  G_APIKey: "AIzaSyDrlDapT2LC38At3uGQB9XOTL7LntIutLg",
  latitude: 51.509865,
  longitude: -0.118092,
};
