<app-header></app-header>
<section class="login-section">
  <div class="container-fluid">
    <div class="row justify-content-center align-items-center">
      <div class="col-xl-4 col-sm-7 col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center mb-3">
              <img src="../../../assets/img/logo-black.svg" alt="123" class="img-fluid">
            </div>
            <div *ngIf="!isLinkValid" class="pb-3">
              <h2 class="fw-bold">{{labelConstants.resetPassword}}</h2>
              <p>{{labelConstants.pleaseEnterAndConfirmNewPassword}}
              </p>
            </div>
            <form class="login-from" [formGroup]="passwordForm" (ngSubmit)="submitResetPassForm()">
              <div *ngIf="!isLinkValid" class="form-group mb-3 position-relative">
                <label for="exampleInputPassword" class="form-label">{{labelConstants.password}}<span
                    class="text-danger">*</span></label>
                <input oninput="this.value = this.value.replace(/^\s+/g, '')"
                  [type]="showNewPassword ? 'text' : 'password'" class="form-control" formControlName="NewPassword"
                  placeholder="Enter New password" maxlength="99">
                <i class="fa-regular fa-eye visibility cursor" *ngIf="!showNewPassword"
                  (click)="togglePasswordVisibility('showNewPassword')"></i>
                <i class="fa-regular fa-eye-slash visibility cursor" *ngIf="showNewPassword"
                  (click)="togglePasswordVisibility('showNewPassword')"></i>
                <span class="text-danger"
                  *ngIf="submitted && ChangePassControls['NewPassword'].errors?.['required']">{{errorConstants.NewPasswordRequired}}</span>
                <span class="text-danger"
                  *ngIf="submitted && ChangePassControls['NewPassword'].errors?.['pattern']">{{errorConstants.NewPasswordNotValid}}</span>
              </div>
              <div *ngIf="!isLinkValid" class="form-group position-relative">
                <label for="exampleInputConfirmPassword" class="form-label">{{labelConstants.confirmPassword}}<span
                    class="text-danger">*</span></label>
                <input oninput="this.value = this.value.replace(/^\s+/g, '')"
                  [type]="showConfirmPassword ? 'text' : 'password'" class="form-control"
                  formControlName="ConfirmNewPassword" placeholder="Enter Confirm password" maxlength="99">
                <i class="fa-regular fa-eye visibility cursor" *ngIf="!showConfirmPassword"
                  (click)="togglePasswordVisibility('showConfirmPassword')"></i>
                <i class="fa-regular fa-eye-slash visibility cursor" *ngIf="showConfirmPassword"
                  (click)="togglePasswordVisibility('showConfirmPassword')"></i>
                <span class="text-danger"
                  *ngIf="submitted && ChangePassControls['ConfirmNewPassword'].errors?.['required']">{{errorConstants.ConfirmPasswordRequired}}</span>
                <span class="text-danger"
                  *ngIf="submitted && passwordForm.hasError('mismatch') && passwordForm.get('ConfirmNewPassword').touched">
                  {{errorConstants.NotMatched}}
                </span>
              </div>
              <div class="text-center" *ngIf="isLinkValid">
                <p>{{labelConstants.thisLinkIsExpiredPleaseResendLinkToResetPassword}}</p>
              </div>
              <button *ngIf="!isLinkValid" type="submit" class="login-btn mt-4 mb-4">{{labelConstants.resetPassword}}
              </button>
              <div class="d-flex justify-content-center mb-3">
                <a routerLink="/login">{{labelConstants.goToBack}}</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
  </div>
</section>
<app-login-register-footer></app-login-register-footer>