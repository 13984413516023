export const CommonErrorMessages = {
  ConfirmPasswordRequired: 'Confirm password is required.',
  EmailNotValid: 'Email is not valid.',
  EmailRequired: 'Email is required.',
  FirstNameRequired: 'First Name is required.',
  LastNameIsRequired: 'Last Name is required.',
  NewPasswordRequired: 'New password is required.',
  OldPasswordRequired: 'Old password is required.',
  PasswordRequired: 'Password is required.',
  PasswordsDoNoMatch: "New password and confirm password does not matched.",
  PasswordValidations: 'Password requires min 8 char, max 16 char, one upper case, one lower case, one special character, one number and without any space.',
};

export const ContactErrorConstants = {
  EmailRequired: ' Email is required.',
  EmailNotValid: 'Email is not Valid.',
  FirstNameRequired: 'First name is required.',
  LastNameRequired: 'Last name is required.',
  MessageRequired: 'Message is required.',
  PhoneNumberRequired: 'Phone number is required.',
  PhoneNotValid: 'Phone number must contain 10 digits.',
  PrivacyPolicyRequired: 'Accept privacy policy.'
}

export const OrderConstants = {
  EmailRequired: ' Email is required.',
  EmailNotValid: 'Email is not Valid.',
  FirstNameRequired: 'First name is required.',
  LastNameRequired: 'Last name is required.',
  MessageRequired: 'Message is required.',
  PhoneNumberRequired: 'Phone number is required.',
  PhoneNotValid: 'Phone number must contain 10 digits.',
  BillingAddressRequired: 'Please enter billing address',
  CountryRequired: 'Please select country',
  AddressLine2Required: 'Please enter address line 2',
  CityRequired: 'Please enter town/city',
  PostalCodeRequired: 'Please select postal code',
  PostalCodeNotValid: 'Please enter valid postal code'
}

export const eventQuestions = {
  EventQty: `Event quantity may not greater than event's max participants`
}

export const ResetPasswordErrorMessages = {
  NewPasswordRequired: "New password is required.",
  NewPasswordNotValid: "Password requires min 8 char, max 16 char, one upper case, one lower case, one special character, one number and without any space.",
  ConfirmPasswordRequired: "Confirm password is required.",
  NotMatched: "New password and confirm password does not matched."
}

export const MyProfileErrorMessages = {
  firstNameRequired: "First name is required.",
  lastNameRequired: "Last name is required.",
  mobileNumberRequired: "Mobile number is required.",
  mobileNumberNotValid: "Mobile number is not valid.",
  addressRequired: "Address line 1 is required.",
  stateRequired: "Address line 2 is required.",
  cityRequired: "Town/City is required.",
  countryRequired: "Country is required.",
  zipCodeRequired: "Zipcode is required.",
  invalidZipCode: "Zipcode is not valid.",
  requestedQuantityCanNotBeZero: "Requested quantity cannot be zero or greater than total quantity.",
  refundQuantityIsRequired: "Refund quantity is required.",
  refundReasonRequired: "Refund reason is required.",
  reportReasonReqired: "Report reason is required.",
  oldPasswordRequired: "Old Password is required.",
  newPasswordRequired: "New Password is required.",
  strongPassword: "Password requires min 8 char, max 16 char, one upper case, one lower case, one special character, one number and without any space.",
  confirmPasswordRequired: "Confirm Password is required.",
  passwordNotMatched: " New password and confirm password does not matched.",
  emailIsRequired: "Email is required."
}