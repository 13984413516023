import { Component, ElementRef, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiUrlHelper } from 'src/app/common/api-url-helper';
import { FooterLabelConstant } from 'src/app/constants/LabelMessages';
import { CommonService } from 'src/app/services/common/common.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  public appVersion = 1; // packageInfo.version
  public currentYear: number = new Date().getFullYear();
  TestimonialList: any = [];
  images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);
  facebookUrl: any;
  twitterUrl: any;
  instagramUrl: any;
  youtubeUrl: any;
  currentDay: any;
  labelConstant: any;

  constructor(private api: ApiUrlHelper, private spinner: NgxSpinnerService, private toastr: ToastrService,
    private commonService: CommonService , private elementRef: ElementRef) { }

  ngOnInit() {
    this.labelConstant = FooterLabelConstant;
    this.getTestimonialList();
    this.getSocialMediaLinks();
    this.currentDay = Date();
  }

  getTestimonialList() {
    let api = this.api.apiUrl.Testimonial.GetTestimonialList;
    this.spinner.show();
    this.commonService.doGet(api).pipe().subscribe({
      next: (response) => {
        if (response.success) {
          this.TestimonialList = response.data;
          this.spinner.hide();
        }
        else {
          this.toastr.error(response.message);
          this.spinner.hide();
        }
      },
      error: (err) => {
        this.spinner.hide();
        console.log(err);
      }
    });
  }

  getSocialMediaLinks() {
    let searchStr = "social media link"
    let api = this.api.apiUrl.SocialMediaLinks.GetSocialMediaLinks.replace('{Id}', searchStr);;
    this.spinner.show();
    this.commonService.doGet(api).pipe().subscribe({
      next: (response) => {
        if (response.success) {
          this.spinner.hide();
          this.facebookUrl = response.data[0];
          this.instagramUrl = response.data[1];
          this.twitterUrl = response.data[2];
          this.youtubeUrl = response.data[3];
        }
      },
      error: (err) => {
        this.spinner.hide();
        console.log(err);
      }
    });
  }

  navigate(url) {
    window.open(url, '_blank')
  }

  redirectToTop() {
    this.elementRef.nativeElement.ownerDocument.defaultView.scrollTo({
      top: 0,
      behavior: 'smooth' // Optional: Smooth scrolling animation
    });
  }
}