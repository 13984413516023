import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiUrlHelper } from 'src/app/common/api-url-helper';
import { OTPVerifyLabelConstant } from 'src/app/constants/LabelMessages';
import { CommonService } from 'src/app/services/common/common.service';
import { SharedService } from 'src/app/services/shared-service/shared.service';

@Component({
  selector: 'app-otp-verify',
  templateUrl: './otp-verify.component.html',
  styleUrls: ['./otp-verify.component.scss']
})

export class OtpVerifyComponent implements OnInit {

  registerData: any;
  email: any;
  encryptedOtp: any;
  otp: string;
  labelConstant: any;

  constructor(private service: CommonService, private api: ApiUrlHelper, private toastr: ToastrService, private spinner: NgxSpinnerService, private route: Router, private sharedService: SharedService, private elementRef: ElementRef) { }

  ngOnInit() {
    this.labelConstant = OTPVerifyLabelConstant;
    this.sharedService.setData('Verification');
    this.service.setBannerData('');
    this.registerData = JSON.parse(localStorage.getItem('registerData'));
    this.encryptedOtp = this.service.Decrypt(localStorage.getItem('Otp')).replace(' ', '');
    this.email = this.registerData.Email;
  }

  onOtpChange(event: any) {
    this.otp = event;
  }

  verifyOtp() {
    if (this.otp == this.encryptedOtp) {
      this.spinner.show();
      let api = this.api.apiUrl.Account.CustomerSignUp;
      this.service.doPost(api, this.registerData).pipe().subscribe({
        next: (response) => {
          this.toastr.success(response.message);
          this.service.clearLocalStorage(false);
          this.route.navigate(['/login']);
          this.spinner.hide();
        },
        error: (err) => {
          this.spinner.hide();
        }
      })
    }
    else {
      this.spinner.hide();
      this.toastr.error("Please enter valid verification code")
    }
  }

  redirectToTop() {
    this.elementRef.nativeElement.ownerDocument.defaultView.scrollTo({
      top: 0,
      behavior: 'smooth' // Optional: Smooth scrolling animation
    });
  }
}