import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderChangeService {


  private fileUpload$ = new BehaviorSubject<any>({});
  myfileUpload$ = this.fileUpload$.asObservable();

  setLoaderMessage(message?: any) {
    this.fileUpload$.next(message ?? {});
  }
}