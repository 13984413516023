import { Component, ElementRef, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiUrlHelper } from 'src/app/common/api-url-helper';
import { LoginLabelConstants } from 'src/app/constants/LabelMessages';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-login-register-footer',
  templateUrl: './login-register-footer.component.html',
  styleUrls: ['./login-register-footer.component.scss']
})
export class LoginRegisterFooterComponent implements OnInit {

  facebookUrl: any;
  twitterUrl: any;
  instagramUrl: any;
  youtubeUrl: any;
  labelConstants: any;

  constructor(
    private spinner: NgxSpinnerService,
    private api: ApiUrlHelper,
    private commonService: CommonService,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    this.labelConstants = LoginLabelConstants;
    this.getSocialMediaLinks();
  }

  getSocialMediaLinks() {
    let searchStr = "social media link"
    let api = this.api.apiUrl.SocialMediaLinks.GetSocialMediaLinks.replace('{Id}', searchStr);
    this.spinner.show();
    this.commonService.doGet(api).pipe().subscribe({
      next: (response) => {
        if (response.success) {
          this.spinner.hide();
          this.facebookUrl = response.data[0];
          this.instagramUrl = response.data[1];
          this.twitterUrl = response.data[2];
          this.youtubeUrl = response.data[3];
        }
      },
      error: (err) => {
        this.spinner.hide();
        console.log(err);
      }
    });
  }

  redirectToTop() {
    this.elementRef.nativeElement.ownerDocument.defaultView.scrollTo({
      top: 0,
      behavior: 'smooth' // Optional: Smooth scrolling animation
    });
  }
}