<div class="login-footer w-100 ">
  <div class="container">
    <div class="col-lg-12">
      <div class="row">
        <div class="footer-sub col-lg-4 col-xl-4 text-white">
          <div class="footer-media">
            <p class="footer-top">{{labelConstants.CopyRight}}</p>
            <span class="footer-icon d-flex">
              <a [href]="facebookUrl?.text" *ngIf="facebookUrl?.isActive" target="_blank" class="fb-icon">
                <img src="../../../assets/img/fb.svg" alt>
              </a>
              <a [href]="twitterUrl?.text" *ngIf="twitterUrl?.isActive" target="_blank" class="tw-icon">
                <img src="../../../assets/img/twitter.svg" alt>
              </a>
              <a [href]="instagramUrl?.text" *ngIf="instagramUrl?.isActive" target="_blank" class="insta-icon">
                <img src="../../../assets/img/insta.svg" alt>
              </a>
              <a [href]="youtubeUrl?.text" *ngIf="youtubeUrl?.isActive" target="_blank" class="insta-icon">
                <img src="../../../assets/img/Youtube.png" alt>
              </a>
            </span>
          </div>
        </div>
        <div class="footer-order col-lg-4 col-xl-4  text-center">
          <div class="info">
            <img routerLink="/" src="../../../assets/img/logo-white.svg" alt class="pointer">
          </div>
        </div>
        <div class="col-lg-4 col-xl-4  footer-text">
          <div
            class="d-flex align-items-center align-items-lg-end flex-column align-items-md-center justify-content-center  justify-content-lg-end">
            <p class="footer-top">
              <span routerLink="/privacy-policy">
                {{labelConstants.PrivacyPolicy}} |
              </span>
              <span routerLink="/terms-conditions">
                {{labelConstants.TermsAndCondition}}
              </span>
            </p>
            <a class="footer-last"><i (click)="redirectToTop()" class="fa-solid fa-chevron-up"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>