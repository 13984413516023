import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MenuSidebarComponent } from './menu-sidebar/menu-sidebar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { LoginRegisterFooterComponent } from './login-register-footer/login-register-footer.component';
import { LogoutComponent } from './logout/logout.component';
import { OrdinalDatePipe } from '../services/common/ordinal-date.pipe';

@NgModule({
  declarations: [FooterComponent, HeaderComponent,OrdinalDatePipe, MenuSidebarComponent, ConfirmationDialogComponent , LoginRegisterFooterComponent, LogoutComponent],
  imports: [CommonModule, NgbModule, RouterModule],
  exports: [FooterComponent, HeaderComponent, MenuSidebarComponent,OrdinalDatePipe , LoginRegisterFooterComponent],
})
export class SharedModule {}
