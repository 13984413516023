import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from '../common/common.service';
import { ApiUrlHelper } from 'src/app/common/api-url-helper';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private numeroSubject$ = new BehaviorSubject<string>('0');
  numero$: Observable<string> = this.numeroSubject$.asObservable();
  private checkoutStarted: boolean = false;

  constructor(
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private api: ApiUrlHelper
  ) { }

  updateNumero(newNumero: string) {
    this.numeroSubject$.next(newNumero);
  }

  getTaxPercentage(): Promise<number> {
    let searchStr = "TaxPercentage";
    let api = this.api.apiUrl.SocialMediaLinks.GetSocialMediaLinks.replace('{Id}', searchStr);
    this.spinner.show();
    // Return a new Promise
    return new Promise<number>((resolve, reject) => {
      this.commonService.doGet(api).subscribe({
        next: (response) => {
          this.spinner.hide();
          if (response.success) {
            // Resolve the promise with the value
            if (response.data[0].text && response.data[0].text != '') {
              resolve(parseFloat(response.data[0].text));
            }
            else {
              resolve(parseFloat("0"));
            }
          } else {
            // Reject the promise if not successful
            reject('Failed to get tax percentage');
          }
        },
        error: (error) => {
          this.spinner.hide();
          // Reject the promise on error
          reject(error);
        }
      });
    });
  }

  startCheckout() {
    this.checkoutStarted = true;
  }

  isCheckoutStarted(): boolean {
    return this.checkoutStarted;
  }
}