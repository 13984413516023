import { Component, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiUrlHelper } from 'src/app/common/api-url-helper';
import { CommonErrorMessages } from 'src/app/constants/ErrorMessages';
import { LoginLabelConstants } from 'src/app/constants/LabelMessages';
import { CommonService } from 'src/app/services/common/common.service';
import { SharedService } from 'src/app/services/shared-service/shared.service';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent {

  // Common Properties
  loginForm: FormGroup
  submitted: boolean = false;
  isShowPassword: boolean = false;
  errorConstants: any;
  labelConstants: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private api: ApiUrlHelper,
    private commonService: CommonService,
    private storageService: StorageService,
    private sharedService: SharedService,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    this.sharedService.setData('Sign in');
    this.commonService.setBannerData('');
    this.loginForm = this.formBuilder.group({
      Email: ['', [Validators.required, Validators.email, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      password: ['', [Validators.required]],
    });
    this.errorConstants = CommonErrorMessages;
    this.labelConstants = LoginLabelConstants;
    if (localStorage.getItem('authToken')) {
      this.router.navigate(['/']);
    }
  }

  get loginFormControl() {
    return this.loginForm.controls;
  }

  clearLocalStorage() {
    localStorage.removeItem("authToken");
    localStorage.removeItem("email");
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("userImage");
  }

  onLogin() {
    this.submitted = true;
    if (this.loginForm.invalid || !this.loginForm.value.Email || !this.loginForm.value.password) {
      return;
    }
    this.spinner.show();
    let LoginObj = {
      Email: this.loginForm.value.Email,
      Password: this.loginForm.value.password,
      CustomerUID: ((this.storageService.getValue('Fingerprint') && this.storageService.getValue('Fingerprint') != "") ? this.storageService.getValue('Fingerprint') : null)
    }
    let apiUrl = this.api.apiUrl.Account.CustomerLogin;
    this.commonService.doPost(apiUrl, LoginObj).pipe().subscribe({
      next: (response) => {
        if (response.success) {
          this.spinner.hide();
          this.storageService.setValue('authToken', response.data.token);
          this.storageService.setValue('email', response.data.email);
          this.storageService.setValue('userId', response.data.customerId);
          this.storageService.setValue('userName', response.data.firstName + ' ' + response.data.lastName);
          this.storageService.setValue('userImage', response.data.customerImage);
          localStorage.removeItem("Fingerprint");
          this.router.navigate(['/']);
        }
        else {
          this.spinner.hide();
          this.toast.error(response.message);
        }
      },
      error: (er) => {
        this.spinner.hide();
        console.error(er);
        this.clearLocalStorage();
      },
    });
  }

  togglePasswordVisibility() {
    this.isShowPassword = !this.isShowPassword
  }

  onForgotPassword() {
    this.router.navigate(['/forgot-password']);
  }
  onRegistration() {
    this.router.navigate(['/registration']);
  }

  redirectToTop() {
    this.elementRef.nativeElement.ownerDocument.defaultView.scrollTo({
      top: 0,
      behavior: 'smooth' // Optional: Smooth scrolling animation
    });
  }
}