import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { HTTPListener, HTTPStatus } from './interceptor/HTTPListener';
import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { RouteReuseService } from './services/route-reuse/RouteReuseService';
import { MainComponent } from './layout/main/main.component';
import { SharedModule } from './component/shared.module';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ResetPassComponent } from './pages/reset-pass/reset-pass.component';
import { LoaderComponent } from './component/loader/loader.component';
import { OtpVerifyComponent } from './pages/otp-verify/otp-verify.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgSelect2Module } from 'ng-select2';
import { LoginComponent } from './pages/login/login.component';
import { LoginModelComponent } from './pages/login-model/login-model.component';
import { UnderMainatanceComponent } from './pages/under-mainatance/under-mainatance.component';
import { ServiceWorkerModule } from '@angular/service-worker';

const RxJS_Services = [HTTPListener, HTTPStatus];

@NgModule({
  declarations: [AppComponent, MainComponent, ForgotPasswordComponent, RegistrationComponent, ResetPassComponent, LoaderComponent, OtpVerifyComponent , LoginComponent,LoginModelComponent, UnderMainatanceComponent],
  imports: [BrowserModule, AppRoutingModule, NgbModule, SharedModule, ReactiveFormsModule, FormsModule, ToastrModule.forRoot(),
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }), HttpClientModule, BrowserAnimationsModule, NgbCarouselModule , NgOtpInputModule , NgSelect2Module, ServiceWorkerModule.register('ngsw-worker.js', {
  enabled: !isDevMode(),
  // Register the ServiceWorker as soon as the application is stable
  // or after 30 seconds (whichever comes first).
  registrationStrategy: 'registerWhenStable:30000'
})],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: RouteReuseService,
    },
    RxJS_Services,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPListener,
      multi: true,
    },
    DatePipe,
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }