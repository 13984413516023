import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './layout/main/main.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { ResetPassComponent } from './pages/reset-pass/reset-pass.component';
import { OtpVerifyComponent } from './pages/otp-verify/otp-verify.component';
import { LoginComponent } from './pages/login/login.component';
import { UnderMainatanceComponent } from './pages/under-mainatance/under-mainatance.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login'
    }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: {
      title: 'Forgot Password'
    }
  },
  {
    path: 'registration',
    component: RegistrationComponent,
    data: {
      title: 'Customer Registration'
    }
  },
  {
    path: 'resetpass/:Id/:time',
    component: ResetPassComponent,
    data: {
      title: 'Reset Password'
    }
  },
  {
    path: 'verifyotp',
    component: OtpVerifyComponent,
    data: {
      title: 'Otp Verification'
    }
  },
  {
    path: 'under-maintenance',
    component: UnderMainatanceComponent
  },
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./layout/main/main.module').then((m) => m.MainModule),
      }
    ]
  },
  {
    path: '**',
    loadChildren: () =>
      import('./pages/page-not-found/page-not-found.module').then(
        (m) => m.PageNotFoundModule
      )
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})

export class AppRoutingModule { }