import { Component, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiUrlHelper } from 'src/app/common/api-url-helper';
import { CommonErrorMessages } from 'src/app/constants/ErrorMessages';
import { ForgotPassLabelConstants } from 'src/app/constants/LabelMessages';
import { CommonService } from 'src/app/services/common/common.service';
import { SharedService } from 'src/app/services/shared-service/shared.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})

export class ForgotPasswordComponent {

  // Common Properties
  emailForm: FormGroup;
  isEmailSend: boolean = false;
  submitted: boolean = false;
  errorConstants: any;
  labelConstants: any;

  constructor(
    private spinner: NgxSpinnerService,
    private api: ApiUrlHelper,
    private toastr: ToastrService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private sharedService: SharedService,
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    this.sharedService.setData('Forgot Password');
    this.commonService.setBannerData('');
    this.emailForm = this.fb.group({
      UserEmail: ['', [Validators.required, Validators.email, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]]
    });
    this.errorConstants = CommonErrorMessages;
    this.labelConstants = ForgotPassLabelConstants;
  }

  get emailFormValidations() {
    return this.emailForm.controls;
  }

  submitForgotPassForm() {
    this.submitted = true;
    if (this.emailForm.valid) {
      this.submitted = false;
      this.spinner.show();
      let api = this.api.apiUrl.Account.CustomerForgotPass;
      let ForgotPassRequestModel = {
        EmailId: this.emailForm.value.UserEmail,
      }
      this.commonService.doPost(api, ForgotPassRequestModel).pipe().subscribe({
        next: (response) => {
          if (response.success) {
            this.spinner.hide();
            this.toastr.success(response.message);
            this.isEmailSend = true;
            this.emailForm.reset();
          }
          else {
            this.spinner.hide();
            this.toastr.error(response.message);
          }
        }
        ,
        error: (err) => {
          this.spinner.hide();
        }
      })
    }
  }

  redirectToTop() {
    this.elementRef.nativeElement.ownerDocument.defaultView.scrollTo({
      top: 0,
      behavior: 'smooth' // Optional: Smooth scrolling animation
    });
  }
}