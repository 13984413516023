<app-header></app-header>
<section class="login-section">
    <div class="container-fluid">
        <div class="row justify-content-center align-items-center">
            <div class="col-xl-4 col-sm-12 col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="text-center mb-3">
                            <img src="../../../assets/img/logo-black.svg" alt="123" class="img-fluid">
                        </div>
                        <div class="pb-3">
                            <h2 class="fw-bold">{{labelConstants.ForgotPass}}</h2>
                            <p>{{labelConstants.SendLink}}</p>
                        </div>
                        <form class="login-from" [formGroup]="emailForm" (ngSubmit)="submitForgotPassForm()">
                            <div class="form-group mb-3">
                                <label for="exampleInputEmail1" class="form-label">{{labelConstants.Emailaddress}}<span
                                        class="text-danger">*</span></label>
                                <input type="email" class="form-control"
                                    oninput="this.value = this.value.replace(/^\s+/g, '')" formControlName="UserEmail"
                                    id="exampleInputEmail1" placeholder="Enter Email"
                                    oninput="this.value = this.value.toLowerCase()" aria-describedby="emailHelp"
                                    maxlength="100">
                                <span class="text-danger"
                                    *ngIf="submitted && emailFormValidations['UserEmail'].errors?.['required']">
                                    {{errorConstants.EmailRequired}}</span>
                                <span class="text-danger"
                                    *ngIf="submitted && emailFormValidations['UserEmail'].errors?.['pattern']">
                                    {{errorConstants.EmailNotValid}}</span>
                            </div>
                            <button type="submit" class="login-btn mt-4 mb-4">{{labelConstants.Send}}</button>
                            <div class="d-flex justify-content-center mb-3">
                                <a routerLink="/login">{{labelConstants.GoBack}}</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-login-register-footer></app-login-register-footer>