import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LogoutModalLabelConsant } from 'src/app/constants/LabelMessages';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent {

  constructor(public activeModal:NgbActiveModal){}
  labelConstant : any;

  @Input() titleContent: any; // Declare an input property to receive data from parent component
  @Input() title:any;
  @Input() heading:any;

  ngOnInit() {
    this.labelConstant = LogoutModalLabelConsant;
  }
}