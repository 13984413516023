<app-header></app-header>
<section class="login-section">
  <div class="container-fluid">
    <div class="row justify-content-center align-items-center">
      <div class="col-xl-4 col-sm-7 col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center mb-3">
              <img src="../../../assets/img/logo-black.svg" alt="" class="img-fluid">
            </div>
            <h2 class="fw-bold pb-3">{{labelConstants.Signin}}</h2>
            <form class="login-from" [formGroup]="loginForm" (ngSubmit)="onLogin()">
              <div class="form-group mb-3">
                <label for="exampleInputEmail1" class="form-label">{{labelConstants.Emailaddress}}<span>*</span></label>
                <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Enter Email"
                  formControlName="Email" aria-describedby="emailHelp"
                  oninput="this.value = this.value.replace(/^\s+/g, '')" oninput="this.value = this.value.toLowerCase()"
                  maxlength="100">
                <span class="text-danger" *ngIf="submitted && loginFormControl['Email'].errors?.['required']">
                  {{errorConstants.EmailRequired}}</span>
                <span class="text-danger" *ngIf="submitted && loginFormControl['Email'].errors?.['pattern']">
                  {{errorConstants.EmailNotValid}}</span>
              </div>
              <div class="form-group mb-3">
                <label for="exampleInputPassword1" class="form-label">{{labelConstants.Password}}<span>*</span></label>
                <div class="position-relative">
                  <input placeholder="Enter Password" oninput="this.value = this.value.replace(/^\s+/g, '')"
                    [type]="isShowPassword ? 'text' : 'password'" formControlName="password" class="form-control"
                    id="inputPassword2" maxlength="100" />
                  <div class="icon-set" (click)="togglePasswordVisibility()">
                    <img
                      [src]="isShowPassword == true ?'../../../assets/img/eye_open.svg': '../../../assets/img/eye_close.svg'" alt="">
                  </div>
                </div>
                <span class="text-danger" *ngIf="submitted && loginFormControl['password'].errors?.['required']">
                  {{errorConstants.PasswordRequired}}</span>
              </div>
              <a (click)="onForgotPassword()" id="emailHelp" class="form-text">{{labelConstants.Forgotpassword}}</a>
              <button type="submit" class="login-btn mt-4 mb-4">{{labelConstants.Signin}}</button>
              <div class="form-check d-flex justify-content-center mb-3">
                <label class="form-check-label" for="exampleCheck1">{{labelConstants.DontHaveAccount}}
                  <a (click)="onRegistration()"
                    style="color:  #667085; padding-left: 10px;">{{labelConstants.SignUpNow}}</a>
                </label>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-login-register-footer></app-login-register-footer>