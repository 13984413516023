import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { LoaderChangeService } from 'src/app/services/loader/loader-change.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

  message: string = "";
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private LoaderChangeService: LoaderChangeService) { }

  ngOnInit() {
    this.message = "Loading...";
    this.LoaderChangeService.myfileUpload$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.message = Object.keys(value).length ? value : "Loading...";
      });
  }
}
